<template>
    <div class="">
        <div class="row">
            <div class="col-lg-7">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group mb-3">
                            <label for="inputTitle">
                                Title <span class="text-danger small">*</span>
                            </label>
                            <input type="text" v-validate="{ required: true}" v-model="data.title"
                            id="inputTitle" name="title" class="form-control" :class="{ 'is-invalid': errors.has('title') }"
                            placeholder="Plan title" />
                            <span class="text-danger small"  v-show="errors.has('title')">{{ errors.first('title') }}</span>
                        </div>
                    </div>
                     <div class="col-md-6">
                        <div class="form-group mb-3">
                            <label class="">Billing period</label>
                            <select class="custom-select" v-validate="{required: true}" v-model="data.billing_period"
                            name="billing_period" placeholder="--select billing period--" :class="{ 'is-invalid': errors.has('billing_period')}">
                                <option disabled value="">--select billing period--</option>
                                <option value="month">Monthly</option>
                                <option value="quarter">Quartely</option>
                                <option value="year">Yearly</option>
                            </select>
                            <span class="text-danger small"  v-show="errors.has('billing_period')">{{ errors.first('billing_period') }}</span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group mb-3">
                            <label for="inputPrice">
                                Unit price ($) <span class="text-danger">*</span>
                            </label>
                            <input type="number" v-validate="{ required: true, regex: /^[0-9]+$/}" v-model="data.unit_price" class="form-control"
                            name="price" id="inputPrice" placeholder="Enter Price" />
                            <span class="text-danger" v-show="errors.has('price')">{{ errors.first('price') }}</span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group mb-3">
                            <label for="inputPriceSlash">
                                Price slash ($) <span class="text-danger">*</span>
                            </label>
                            <input type="number" v-validate="{ required: true, regex: /^[0-9]+$/}" v-model="data.price_slash" class="form-control"
                            name="price_slash" id="inputPriceSlash" placeholder="Enter price slash" />
                            <span class="text-danger" v-show="errors.has('price_slash')">{{ errors.first('price_slash') }}</span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group mb-3">
                            <label for="inputSortorder">
                                Sort order <span class="text-danger">*</span>
                            </label>
                            <input type="number" v-validate="{ required: true, regex: /^[0-9]+$/}" v-model="data.sort_order" class="form-control"
                            name="sort_order" id="inputSortorder" placeholder="Enter sort order" />
                            <span class="text-danger" v-show="errors.has('sort_order')">{{ errors.first('sort_order') }}</span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group mb-3">
                            <label for="inputTaskLimit">
                                Task limit <span class="text-danger">*</span>
                            </label>
                            <input type="number" v-validate="{ required: true, regex: /^[0-9]+$/}" v-model="data.task_limit" class="form-control"
                            name="task_limit" id="inputTaskLimit" placeholder="Enter task limit" />
                            <span class="text-danger" v-show="errors.has('task_limit')">{{ errors.first('task_limit') }}</span>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group mb-3">
                            <label class="form-label" for="more_info">More info</label>
                            <textarea id="more_info" v-validate="{ max: 200}"
                                v-model="data.more_info" :class="{ 'is-invalid': errors.has('more_info')}" class="form-control" rows="2"
                                placeholder="Write more info" name="more_info"></textarea>
                            <span class="text-danger small"  v-show="errors.has('more_info')">{{ errors.first('more_info') }}</span>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group mb-3">
                            <label class="form-label" for="description">Description</label>
                            <textarea id="description" v-validate="{ required: true, max: 200}"
                                v-model="data.description" :class="{ 'is-invalid': errors.has('description')}" class="form-control" rows="2"
                                placeholder="Write description" name="description"></textarea>
                            <span class="text-danger small"  v-show="errors.has('description')">{{ errors.first('description') }}</span>
                        </div>
                    </div>
                    <div class="col-6 col-md-4">
                        <div class="form-group mb-3">
                            <b-form-checkbox v-model="data.is_active" name="is_active" switch>
                                Visibility
                            </b-form-checkbox>
                        </div>
                    </div>
                    <div class="col-6 col-md-4">
                        <div class="form-group mb-3">
                            <b-form-checkbox v-model="data.is_unlimited" name="is_unlimited" switch>
                                Unlimited
                            </b-form-checkbox>
                        </div>
                    </div>
                    <div class="col-6 col-md-4">
                        <div class="form-group mb-3">
                            <b-form-checkbox v-model="data.is_popular" name="is_popular" switch>
                                Popular
                            </b-form-checkbox>
                        </div>
                    </div>
                    <div class="col-6 col-md-4">
                        <div class="form-group mb-3">
                            <b-form-checkbox v-model="data.has_trial" name="has_trial" switch>
                                Support trial
                            </b-form-checkbox>
                        </div>
                    </div>
                    <div class="col-6 col-md-4">
                        <div class="form-group mb-3">
                            <b-form-checkbox v-model="data.is_default" name="is_default" switch>
                                Default
                            </b-form-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                    <h5 class="">Features</h5>
                    <div v-for="(attribute, fIndex) in attributes" :key="fIndex" class="row mb-1">
                        <div class="col-12">
                            {{attribute.name}}
                        </div>
                        <div class="col-12">
                            <div v-if="attribute.type == 'check'" class="">
                                <b-form-checkbox v-model="data.features[attribute.id]" 
                                    :name="'checkbox-'+attribute.id"
                                    value="supported" unchecked-value="not_supported">
                                </b-form-checkbox>
                            </div>
                            <div v-if="attribute.type == 'input'" class="">
                                <div class="form-group mb-0">
                                    <input type="text" v-model="data.features[attribute.id]"
                                    class="form-control form-control-sm" placeholder="Enter value" />
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="editMode? updatePlan() : createPlan()"
                    type="button">{{editMode? 'Update':'Submit'}}</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            newFeature: "",
            data: {
                title: "",
                unit_price: null,
                price_slash: null,
                actual_price: null,
                sort_order: 0,
                task_limit: null,
                billing_period: "month",
                is_active: false,
                is_default: false,
                has_trial: false,
                is_unlimited: false,
                is_popular: false,
                more_info: "",
                description: "",
                features: {}
            },
        }
    },
    watch: {
        'data.unit_price': function (price){
            this.getActualPrice()
        },
        'data.billing_period': function (price){
            this.getActualPrice()
        },
    },
    computed: {
        attributes(){
            return this.$store.state.attributeList.data
        },
    },
    methods:{
        createPlan() {
            this.$validator.validateAll().then(result => {
                if (!result) return;
                this.$store.dispatch("changeLoaderValue", true)
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$http.post(`/plans/create`, formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("planList/ADD_PLAN", response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        updatePlan(){
            this.$validator.validateAll().then(result => {
                if (!result) return;
                this.$store.dispatch("changeLoaderValue", true)
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$http.post(`/plans/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$store.commit("planList/UPDATE_PLAN", response.data.data)
                            this.resetForm()
                        }
                    })
            
            })
        },
        getActualPrice(){
            let periodInunit = { "month": 1, "quarter": 3, "year": 12 }
            if(this.data.unit_price && this.data.billing_period){
              this.data.actual_price =  this.data.unit_price * parseInt(periodInunit[this.data.billing_period])
            }else{ this.data.actual_price = null}
        },
        resetForm(){
            this.$validator.reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            this.data = this.$options.filters
                        .patchUpdatables(this.data, this.editItem)
        }
    }
}
</script>