<template>
     <div class="card mb-0 card-pricing ribbon-box">
        <div v-if="plan.is_popular" class="ribbon-two ribbon-two-success"><span>Popular</span></div>
        <div class="card-body text-center">
            <h3 class="text-uppercase">{{plan.title}}</h3>
            <span class="card-pricing-icon text-primary"><i class="fe-at-sign"></i> </span>
            <h2 class="card-pricing-price"> ${{plan.unit_price | money_format}} <sub class="small">/ month</sub></h2>
            <div class="my-2" v-if="plan.billing_period != 'month'">
                <h6 class="font-weight-light">${{ plan.actual_price }} billed {{plan.billing_period}}ly</h6>
                <div v-if="plan.price_slash" class="text-success">
                    ${{plan.price_slash }} Saved!
                </div>
            </div>
            <p class="">{{plan.description}}</p>
            <hr>
            <ul class="list text-left list-unstyled">
                <li v-for="(attribute, fIndex) in attributes" :key="fIndex">
                    <span>
                        <span v-if="attribute.type == 'check'"
                         class="mdi mr-1" :class="plan.features[attribute.id] == 'supported' 
                            ? 'mdi-check text-success': 'mdi-close'"> </span>
                        <span v-if="attribute.type == 'input'">
                            <span class="mdi mr-1" :class="plan.features[attribute.id] 
                            ? 'mdi-check text-success': 'mdi-close'"> </span>
                            {{plan.features[attribute.id]}}
                        </span>
                    </span>
                    <span> {{attribute.name}}</span>
                </li> 
            </ul>
            <hr>
            <div class="row text-left">
                <div class="col-md-4 col-6 col-lg-6">
                    <div>
                        <span class="mdi mr-1" :class="plan.is_active ? 'mdi-check text-success': 'mdi-close'"> </span>
                        <span>Visibility</span>
                    </div>
                </div>
                <div class="col-md-4 col-6 col-lg-6">
                    <div>
                        <span class="mdi mr-1" :class="plan.is_unlimited ? 'mdi-check text-success': 'mdi-close'"> </span>
                        <span>Unlimited</span>
                    </div>
                </div>
                <div class="col-md-4 col-6 col-lg-6">
                    <div>
                        <span class="mdi mr-1" :class="plan.is_popular ? 'mdi-check text-success': 'mdi-close'"> </span>
                        <span>Popular</span>
                    </div>
                </div>
                <div class="col-md-4 col-6 col-lg-6">
                    <div>
                        <span class="mdi mr-1" :class="plan.is_default ? 'mdi-check text-success': 'mdi-close'"> </span>
                        <span>Default</span>
                    </div>
                </div>
                <div class="col-md-4 col-6 col-lg-6">
                    <div>
                        <span class="mdi mr-1" :class="plan.has_trial ? 'mdi-check text-success': 'mdi-close'"> </span>
                        <span>Has trial</span>
                    </div>
                </div>
            </div>
            <hr>
            <slot name="actions"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        plan:{
            type:Object,
            required: true
        }
    },
    computed: {
        attributes(){
            return this.$store.state.attributeList.data
        },
    },
}
</script>